import { useEffect } from 'react';
import SEO from '../seo/SEO';

import { Link, useLocation, useParams } from 'react-router-dom';

import BackToTopButton from '../buttons/BackToTopButton';

const importantTextRegex = /[А-Я]{5,}|[A-Z]{5,}/;

// Subcomponents
const AdvantagesList = ({ advantages }) => (
    advantages?.map((advantage, i) => (
        <div key={i} className="col-sm-6">
            <p className="mb-0">
                <i className="fa fa-arrow-right text-primary me-2"></i>
                {advantage}
            </p>
        </div>
    ))
);

const DescriptionSection = ({ descriptions }) => (
    descriptions && (
        <div>
            <h2 className="text-center gy-3">Допълнителна Информация</h2>
            <br />
            <strong>
                {descriptions.map((desc, i) => (
                    <p key={i} className={importantTextRegex.test(desc) ? 'procedure-text-important' : ''}>
                        {desc}
                    </p>
                ))}
            </strong>
        </div>
    )
);

const FAQSection = ({ faq }) => (
    faq && (
        <div>
            <h3 className="text-center gy-3">Често Задавани Въпроси</h3>
            <br />
            {faq.map((item, i) => (
                <div key={i}>
                    <h5>{item.question}</h5>
                    <ul>
                        {item.answers.map((answer, j) => (
                            <li key={j} style={{ paddingBottom: '10px' }}>{answer}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
);

const PricesSection = ({ prices, renderPrices }) => (
    prices && renderPrices && (
        <div className="text-center">
            <h5>Цената на поцедурите са, както следва:</h5>
            <div>
                {prices.map((price, i) => <li key={i}>{price}</li>)}
            </div>
        </div>
    )
);

const PromosSection = ({ promos }) => (
    promos && (
        <div className="procedure-promos">
            <h2>Актуални промоции:</h2>
            <div>
                {promos.map((promo, i) => <li key={i}><b>{promo}</b></li>)}
            </div>
        </div>
    )
);

const PacketPricesSection = ({ packetPrices }) => (
    packetPrices && (
        <div className="text-center">
            <h5>Пакетни цени:</h5>
            <div>
                {packetPrices.map((price, i) => <li key={i}>{price}</li>)}
            </div>
        </div>
    )
);

function ProcedureDetails({ procedures }) {
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    }, []);

    const { procedureName } = useParams();
    const location = useLocation();
    const pathName = location.pathname.split('/')[1];

    const procedure = procedures.find(x => x.name === procedureName) || {};
    const { imageUrl, shortDescription, descriptions, advantages, packetPrices, prices, faq, promos } = procedure;
    const renderPrices = !promos;

    return (
        <>
            <SEO
                title={shortDescription}
                description={shortDescription}
                canonicalUrl={`https://www.denasbg.com${location.pathname}`}
            />

            <div className="wow zoomIn" data-wow-delay="0.3s">
                <div className="container col-lg-6 py-3">
                    <h1 className="text-center">{shortDescription}</h1>
                    <br />
                    <div>
                        <div style={{ textAlign: 'left' }} className="row gy-2 gx-4 mb-4">
                            <img className="procedure-image" src={imageUrl} alt="Img" />
                            <AdvantagesList advantages={advantages} />
                        </div>
                    </div>
                </div>

                <div className="container col-lg-8 py-3">
                    <div className="row gy-4 gx-4 mb-4">
                        <DescriptionSection descriptions={descriptions} />
                        <FAQSection faq={faq} />
                        <PricesSection prices={prices} renderPrices={renderPrices} />
                        <PromosSection promos={promos} />
                        <PacketPricesSection packetPrices={packetPrices} />
                    </div>
                </div>

                <div className="d-flex justify-content-center mb-2">
                    <Link
                        to={`/${pathName}`}
                        className="btn btn-sm btn-primary px-3 border-end"
                        style={{ borderRadius: '30px 30px 30px 30px' }}
                    >
                        Обратно към Услуги
                    </Link>
                </div>
            </div>
            <BackToTopButton />
        </>
    );
}

export default ProcedureDetails;