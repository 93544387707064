import { adjustPrice } from '../utils/priceConverter';

const bodyProceduresData = [
    {
        name: 'verju',
        price: `${adjustPrice(150, 0)} лв.`,
        imageUrl: '/img/gallery/verju-1.jpg',
        shortDescription: 'Зелен лазер за отслабване и прамахване на целулит Verju',
        descriptions: [
            'Клинично значими резултати само за две седмици!',
            'Лазерна система Erchonia Verjú е нов революционен уред, създаден специално за лечение на целулит и мастни отлагания по цялото тяло. Verjú използва патентована линия на Erchonia за нискоенергийни лазери, за терапия на неравномерно натрупаните мазнини в областта на бедрата, задните части и долната част на корема.',
            'Лазерна система Erchonia Verjú е доказана чрез двойно- сляпо, мултицентрирано, плацебо-контролирано проучване. Това е единственият FDA сертифициран нискоенергиен лазер на пазара за терапия на целулит.',
            'Зеленият липолазер Verju извежда мазнините от тялото и подпомага пречистването на организма.',
            'С мощното си въздействие зелен лазер Verju не просто намалява обиколките, а успоредно с това ни освобождава от токсините и изглажда кожата.',
            'Първият и единствен зелен лазер Verju за третиране на тялото работи неинвазивно, затова по време на процедурата няма да усетите никакъв дискомфорт. След обработката на  проблемните зони (ръцете, торса, задните части и бедрата), те се стягат, целулитът видимо намалява, а резултатите се запазват дълготрайно.',
            'Липолазерът работи едновременно върху естетическите и здравословни параметри на тялото.',
            'За разлика от други процедури за намаляване на обиколки и целулит, тази е напълно безболезнена.',
            'Прилага се независимо от индекса на телесната маса.',
            'Възможност за интензивно третиране на зоните, които изискват повече внимание.',
            'За разлика от други лазери, след терапията с Verju не е необходимо физическо натоварване за изхвърляне на мазнините. За ускоряване на изтласкването им се препоръчват леки физически упражнения и здравословен начин на хранене, а също прием на повече вода .',
            'Отлични резултати при пациенти, при които други неинвазивни методи не са довели до успех.'
        ],
        advantages: ['Безболезнена процедура', 'Намалява целулита', 'Неинвазивна процедура', 'Редуцира холестерола', 'Целодишна процедура', 'Стяга отпуснатата кожа', 'Без значение от индекса на телесна маса', 'Премахва мастните отлагания по тялото'
        ],
        faq: [
            {
                question: 'Как протича процедурата?',
                answers: [
                    'Процедурата протича без разрези, болка или загряване.',
                    'Системата Verju се състои от 6 различни лазера, които работят върху проблемните зони на тялото за определените минути.'
                ]
            },
            {
                question: 'Какво се случва по време на процедурата?',
                answers: [
                    'Приложената енергия втечнява натрупаните под кожата излишни мазнини.',
                    'Лазерът създава отвор в клетките, през който ненужните натрупвания изтичат заедно с токсините и се изхвърлят по естествен път чрез лимфата.'
                ]
            },
            {
                question: 'Има ли възстановителен период?',
                answers: [
                    'Напълно неинвазивна терапия без странични ефекти.',
                    'Процедурата не изисква време за възстановяване.'
                ]
            },
            {
                question: 'Период на ефективност и продължителност на периода?',
                answers: [
                    'Положителни промени се наблюдават в текстурата на кожата още след първата процедура.',
                    'Оптималните резултати се очакват 1 седмица след последната манипулация.'
                ]
            },
            {
                question: 'Какъв е препоръчителният брой и честота на процедурите?',
                answers: [
                    'Правят се между 6 и 10 процедури, всяка с продължителност до 40 минути.',
                    'Приложението им е веднъж или два пъти седмично в зависимост от индивидуалните нужди на всеки пациент.'
                ]
            }
        ],
        prices: ['20 минути – 100 лева на третираните зони', '40 минути – 150 лева на третирани зони'],
        // promos: [`Отслабване със зелен лазер Verju 20мин. - От 100лв. на ${adjustPrice(100, 30)}лв.`, `Отслабване със зелен лазер Verju 40мин. - От 150лв. на ${adjustPrice(150, 30)}лв.`,],
        packetPrices: ['При закупуване на 5 процедури получавате 1 безплатно и - 20% от крайната цена.', 'При закупуване на 8 процедури получавате 2 безплатно и - 20% от крайната цена.'],
    },
    {
        name: 'lipolazer',
        price: `${adjustPrice(100, 0)} лв.`,
        imageUrl: '/img/lipolazer.jpg',
        shortDescription: 'Липолазер за труднодостъпни зони',
        descriptions: [
            'ЛИПОЛАЗЕР ЗА ТРУДНОДОСТЪПНИ ЗОНИ',
            'Технологията Липолазер използва ниски нива на лазерна енергия,  която взаимодейства химически и биологически с мастните клетки причинявайки „фотобиомодулация”. Това води до промяна на химичния състав на клетката и предизвиква освобождаване на ензима Липаза, който разгражда триглицеридните молекули на мастни киселини и глицерол. Благодарение на този процес те се освобождават през канали в клетъчните мембрани и се транспортират през организма до тъканите, където се използват за производството на енергия при обмяната на веществата т.е. подобряват метаболизма. Този процес е естествена реакция на организма, когато той е принуден да използва собствените си енергийни запаси.',
            'ЗОНИ ЗА ТРЕТИРАНЕ:',
            '1. Двойна брадичка',
            '2. Външната и вътрешната част на бедрата',
            '3. Външната и вътрешната част на ръцете',
            '4. Гръб',
            '5. Корем',
            '6. Седалище',
            '7. Ръце',
            '8. Мастни натрупвания в областта на коленете и глезените',
            'РЕШЕНИЯ НА ПРОБЛЕМИ:',
            '1. Увеличава загубата на мазнини контролирано',
            '2. Трайно намалява обиколката',
            '3. Изглажда целулита',
            '4. Ускорява метаболизма',
            '5. Детоксикира  организма',
            'ТРЕТИРА УСПЕШНО:',
            '1. Неинвазивно извежда натрупаните мазнини по корем, паласки, седалище, вътрешната част на бедрата, външната част на бедрата, вътрешната част на ръцете, външната част на ръцете, бричове, колена.',
            '2. Упорит и трудно лечим  целулит',
            '3. Забавен метаболизм и слаба детоксикация'
        ],
        faq: [
            {
                question: 'Какво представлява процедурата?',
                answers: [
                    'Апаратът Липолазер използва лазерни падове(плочки), които се поставят върху проблемната зона, като например, корем, бедра, ръце, двойна брадичка и други.',
                    'Липолазерът води до изключителни резултати, особено ако се съчетава с тренировки и здравословно хранене, тъй като освен, че намалява мастните натрупвания в организма спомага и за естетичното оформяне на третирана зона, което много трудно се постига само с познатите ни методи.',
                    'Първата процедура води до разрушаванена мастните клетки. Втората процедура намалява обема на мастните тъкани. Третата процедура спомага за задържането на постигнатите резултати, като 8 процедури е оптималния курс.',
                    'Новата технология реално редуцира мазнините в организма, а не като някои други методи, които просто намаляват водното съдържание в тялото.',
                    'Резултатите са видимо намаляване на теглото и поддържане на трайно красива фигура.',
                    'Процедурата продължава между 30 и 40 минути и след поставянето на лазерните падове не се изисква допълнителните услуги на оператор.',
                    'Терапията е приятна и релаксираща, единственото усещане е много леко затопляне, когато падовете са в контакт с кожата.'
                ]
            },
            {
                question: 'Вредна ли е процедурата за организма?',
                answers: [
                    'Технологията Липолазер не вреди на организма и не влияе нито върху кожата, нито върху нервната или кръвоносната система.',
                    'С други думи тя не причинява неестествени за организма реакции.Напълно безболезнена и безопасна процедура, тъй като се използват само ниски нива на лазерната енергия.'
                ]
            },
            {
                question: 'Има ли специален протокол за по-висока ефективност?',
                answers: [
                    'Ние в Денас Център БГ, препоръчваме процедурата да се провежда паралелно с лимфодренаж и специални добавки. Този протокол повишава до 3 пъти бързината и ефекта от процедурите.',
                ]
            },
            {
                question: 'Колко процедури са необходими?',
                answers: [
                    'Препоръчителният курс е от 10 процедури в рамките на 1 месец, т.е. по две процедури всяка седмица.',
                    'Резултатите са видими още след първата процедура – обиколката се намалява с от 1 до 4 сантиметра.'
                ]
            },
        ],
        prices: ['Цена за 1бр. процедура – 100лв.',],
        // promos: [`Цена за 1бр. процедура – От 100лв. на ${adjustPrice(100, 30)}лв.`,],
        // packetPrices: [`Пакетна цена за 10 процедури : От 800.00 лв. на ${adjustPrice(800, 20)}лв.`],
    },
    {
        name: 'zalichavane-stri',
        price: `${adjustPrice(100, 0)} лв.`,
        imageUrl: '/img/strii-1.jpeg',
        shortDescription: 'Заличаване на стрии',
        descriptions: [
            'Заличаването на стрии е едно от най-трудно лечимите кожни несъвършенства.',
            'Бременност , рязка промяна в теглото , бързо израстване , неправилно хранене и редица  здравословни проблеми са едни от най-честите причинители.',
            'Plasmatec е един от високо ефективните методи за борба със стриите по кожата , като клиничните резултати посочват успеваемост до 97%.',
            'Този  метод е нова ера в нехирургичното отстраняване на стрии.',
            'По време на процедурата  се стимулира организма да замени разтегнатата и увредена от стрии кожа  с нова. Човешкото тяло реагира с незабавно бурно производство на нов колаген и еластин, както и на нови клетки, като в третираната зона се формира нова млада кожа.',
            'Процедурата и ефектът от нея наподобява шлифоване на диамант!!!',
            'Прилага се при наличие на тен или пигментации.',
            'Възможност за интензивно третиране на по-големи зони, които изискват повече внимание.',
            'Не се използват ексфолиращи продукти в рамките на 2 седмици след процедурата.',
            'За ускоряване на процеса  се препоръчва използването на правилна домашна грижа.',
            'Отлични резултати при пациенти, при които други неинвазивни методи не са довели до успех.'
        ],
        advantages: ['Безболезнена процедура', 'Целогодишна процедура', 'Минимален срок на възтановяване', 'Неинвазивна процедура',
        ],
        faq: [
            {
                question: 'Как протича процедурата?',
                answers: [
                    'Процедурата протича без разрези, болка или загряване.',
                    'Фракционната термолиза отстранява засегнатата от стрии кожа и кара организма да формира нова.'
                ]
            },
            {
                question: 'Има ли възстановителен период?',
                answers: [
                    'Възстановяването е бързо и ефективно, без продължителни ограничения.'
                ]
            },
            {
                question: 'Период на ефективност и продължителност на периода?',
                answers: [
                    'Положителни промени се наблюдават в текстурата на кожата още след първата процедура.',
                    'Оптималните резултати се очакват до 3 седмици след последната манипулация.'
                ]
            },
        ],
        prices: ['Цена - 5 лв на см2'],
        // promos: [`Цена - От 5 лв за см2 на ${adjustPrice(5, 30)}лв.`],
    },
    {
        name: 'presoterapiq',
        price: `${adjustPrice(70, 0)} лв.`,
        imageUrl: '/img/presa-1.jpg',
        shortDescription: 'Антицелулитен лимфен дренаж (пресотерапия) - BTL Lymphastim',
        descriptions: [
            'С процедурата се прави пневматичен дренаж на краката, корема и дупето, които се поставят в специални "ботуши",  свързани с маркучи към апарат.',
            'Чрез контролирано и последователно подаване на въздух в различните зони на ботуша се постига лимфодренажен и масажиращ ефект. Благодарение на това, чрез лимфата се изхвърлят отпадните продукти от обмяната, а тъканите се насищат с богата на кислород кръв. Целта на апарата е да подобри лимфния ток и да неутрализира застоя на лимфа в долните крайници.',
            'Още след първата процедура ще усетите чувство за лекота и натрупаната в крайниците и тялото умора мигновенно ще изчезне. Обиколката на бедрата и ханша само след няколко процедури,видимо ще намалее и кожата Ви ще стане стегната и красива.',
            'Процедурата е подходяща за всеки, който търси ефективно решение за намаляването на целулита и цялостно подобряване на вида на своята кожа.',
            'След обработката на  проблемните зони (ръцете, торса, задните части и бедрата), те се стягат, целулитът видимо намалява и изчезва, а резултатите се запазват дълготрайно.',
            'Терапията няма възрастови или сезонни ограничения.',
            'Напълно безболезнена процедура.',
            'Възможност за интензивно комбинирано  третиране на зоните, които изискват повече внимание.',
            'Без  необходимост от  физическо натоварване.',
            'Необходим е завишен  прием на  вода.',
            'Отлични резултати при пациенти, при които други неинвазивни методи не са довели до успех.', 'Отлични резултати при пациенти, при които други неинвазивни методи не са довели до успех.'
        ],
        advantages: ['Безболезнена процедура', 'Намаля целулита', 'Неинвазивна процедура', 'Редуцира холестерола', 'Целодишна процедура', 'Стяга отпуснатата кожа', 'Без значение от индекса на телесна маса', 'Премахва мастните отлагания по тялото'],
        packetPrices: ['При закупуване на 5 процедури получавате 1 безплатно', 'При закупуване на 10 процедури получавате 2 безплатно'
        ],
        faq: [
            {
                question: 'Как протича процедурата?',
                answers: [
                    'Процедурата протича без разрези, болка или загряване.',
                    'Лимфопресата работи върху проблемните зони на тялото за определените минути.'
                ]
            },
            {
                question: 'Какво се случва по време на процедурата?',
                answers: [
                    'Антицелулитният лимфен дренаж  създава изключително приятно усещане, като при релаксиращ масаж.',
                    'Въздейства на тялото и меките тъкани да премахне излишните течности с които да подобри обмяната на веществата по лимфните съдове.',
                    'Провокира отвор в клетките, през който ненужните натрупвания изтичат заедно с токсините и се изхвърлят по естествен път чрез лимфата.'
                ]
            },
            {
                question: 'Има ли възстановителен период?',
                answers: [
                    'Напълно неинвазивна терапия без странични ефекти.',
                    'Процедурата не изисква време за възстановяване.'
                ]
            },
            {
                question: 'Период на ефективност и продължителност на периода?',
                answers: [
                    'Положителни промени се наблюдават в текстурата на кожата още след първата процедура.',
                    'Оптималните резултати се очакват 1 седмица след последната манипулация.'
                ]
            },
            {
                question: 'Каква е препоръчителната честота на процедурите?',
                answers: [
                    'Правят се между 6 и 12 процедури, всяка с продължителност от 30 минути. Приложението им е веднъж или два пъти седмично в зависимост от индивидуалните нужди на всеки пациент.'
                ]
            },
        ],
        prices: ['30 минути – 70 лева на третираните зони', '60 минути – 100 лева на третираните зони'],
        // promos: [`30 минути – От 70 лв. на ${adjustPrice (70, 30)}лв. за третирана зона.`, `60 минути – 100 лева на ${adjustPrice (100, 30)}лв. за третирана зона`],
    },
    {
        name: 'btl-xwave',
        price: `${adjustPrice(50, 0)} лв.`,
        imageUrl: '/img/theraphy/shock-2.jpg',
        shortDescription: 'Естетични процедури с акустични вълни - BTL X-wave',
        descriptions: [
            'Едни от най-често срещаните проблеми в процедурите за тяло са упоритият целулит, фиброзиралата тъкан и натрупаните мастни депа. За щастие науката работи в тази насока и е разработила изключително ефективен метод за разбиване на целулит и срастнала тъкан – антицелулитната терапия с акустични вълни BTL X-Wave.',
            'X-Wave са акустични вълни, които ликвидират целулита и моделират тялото, подобряват микроциркулацията и лимфния дренаж. Технологията е една от най-безопасните и най-ефективните в премахването на портокаловата кожа, от която страдат най-вече жените, но все по-често и представители на силния пол.',
            'В процеса на терапията се увеличава пропускливостта на клетъчните мембрани и се активира метаболизмът на клетките.',
            'Акустичните вълни предизвикват механичен масаж, а вибрациите разхлабват и разрушават фиброзните стени, които разделят мастните депа. Именно това предизвиква освобождаване на задържаните в тях мазнини, токсини и вода.',
            'Активира се циркулацията на лимфата, подобрява се кръвообращението, намаляват отоците. Неравностите се заглаждат, като се заформят нови колагенови влакна и до клетките достига повече кислород.',
            'За всички, които държат по естествен и изцяло механичен начин да оформят тялото си, апаратът BTL X-WAVE постига отлични резултати в неинвазивното премахване на целулита и подобряване цялостното състояние на кожата.',
            'Антицелулитната терапия с BTL X-WAVE е напълно безопасна за клиенти от всички възрасти, без болка и дискомфорт, гарантиран видим ефект след първата терапия.',
            'Сериозни странични ефекти и/или алергични прояви не се наблюдават, като освен това акустичните вълни влияят благоприятно при болки в плещите, гърба, коленете и ставите.',
            'Доверете се на нашите специалисти и придайте на кожата си млад, жизнен и гладък вид с антицелулитната терапия с акустични вълни BTL X-Wave. Това e мощно и гъвкаво решение за борба с целулит.',
            'Действие на уреда: ударо-вълновата терапия е високо енергийна механична вълна. Тази вълна въздейства върху структурата на колагена и съединителната тъкан на кожата, подобрявайки кръвообръщението и метаболизма на мастните клетки. Механичния масаж намалява отока и подобрява лимфния дренаж на токсините. Стимулира се образуването на колаген, кожата става по-еластична, а нейната стегнатост става видима само след няколко процедури.',
        ],
        faq: [
            {
                question: 'Какво е времето за лечение?',
                answers: [
                    'Вашият терапевт ще изготви план за лечение за вас.',
                    'Повечето пациенти преминават от 8 до 10 сесии в продължение на няколко седмици.'
                ]
            },
            {
                question: 'Какво е усещането при процедурата?',
                answers: [
                    'Процедурата се основава на излъчването на целеви вибрации в третираната тъкан.',
                    'По време на процедурата ще изпитате интензивна механична стимулация'
                ]
            },
            {
                question: 'Какъв е възстановителния период?',
                answers: [
                    'Възстановителен период почти не е необходим.',
                    'Веднага след процедурата можете да се върнете към ежедневните си дейности.'
                ]
            },
            {
                question: 'Кога мога да видя ефекта от процедурата?',
                answers: [
                    'Това много зависи от третираната зона, нейното състояние и други фактори.',
                    'Видими подобрения се виждат още след първите процедури.'
                ]
            },
            {
                question: 'Какви са възможните странични ефекти?',
                answers: [
                    'Процедурата е безболезнена и безопасна.',
                    'В редки случаи може да се появи леко зачервяване или оток на третираната зона.'
                ]
            },
            {
                question: 'Може ли да се правят комбинации с други апарати и процедури',
                answers: [
                    'Процедурите с апарата X-Wave могат да се извършват самостоятелно или като част от пакетни процедури, включващи радиочестота (Exilis, Vela Shape III), криолиполиза (CoolTech), мезотерапия и други.'
                ]
            },
        ],
        prices: ['Бедра - 70лв.', 'Задно бедро + седалище - 70 лв', 'Цели бедра + седалище - 100лв', 'Корем - 70 лв.', 'Бридж - 50 лв', 'Ръце - 50 лв.', 'Прасци - 50 лв.',],
        // promos: [`Бедра - От 70лв. на ${adjustPrice(70, 30)}лв.`, `Задно бедро + седалище -От 70лв. на ${adjustPrice(70, 30)}лв.`, `Цели бедра + седалище - От 100лв. на ${adjustPrice(100, 30)}лв.`, `Корем - От 70лв. на ${adjustPrice(70, 30)}лв.`, `Бридж - От 50лв. на ${adjustPrice(50, 30)}лв.`, `Ръце - От 50лв. на ${adjustPrice(50, 30)}лв.`, `Прасци - От 50лв. на ${adjustPrice(50, 30)}лв.`],
        packetPrices: ['При закупуване на пакет от 10 процедури получавате - 20% отстъпка от общата цена.'],
    },
];

export { bodyProceduresData };