import { useEffect, useState } from 'react';
import SEO from '../seo/SEO';
import { useLocation } from 'react-router-dom';
import ProcedureItem from './ProcedureItem';
import BackToTopButton from '../buttons/BackToTopButton';

function ProcedureList({ procedures }) {
    const [currentPage, setCurrentPage] = useState(1);
    const proceduresPerPage = 9; // Show 6 procedures per page (2 rows)
    
    useEffect(() => {
        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    });

    // Reset pagination when procedures change
    useEffect(() => {
        setCurrentPage(1);
    }, [procedures]);

    const location = useLocation();
    const pathName = location.pathname;

    let category = '';
    if (pathName === '/body-procedures') {
        category = 'Процедури за Тяло';
    } else if (pathName === '/eyebrows-procedures') {
        category = 'Процедури за Вежди';
    } else if (pathName === '/eyelashes-procedures') {
        category = 'Процедури за Мигли';
    } else if (pathName === '/face-procedures') {
        category = 'Процедури за Лице';
    } else if (pathName === '/therapies') {
        category = 'Терапии и физиотерапии';
    }

    // Calculate total pages
    const totalPages = Math.ceil(procedures.length / proceduresPerPage);

    // Get current procedures to display
    const indexOfLastProcedure = currentPage * proceduresPerPage;
    const indexOfFirstProcedure = indexOfLastProcedure - proceduresPerPage;
    const currentProcedures = procedures.slice(indexOfFirstProcedure, indexOfLastProcedure);

    // Handle page change
    const nextPage = () => {
        if (currentPage < totalPages) {setCurrentPage(currentPage + 1);}
    };

    const prevPage = () => {
        if (currentPage > 1) {setCurrentPage(currentPage - 1);}
    };

    return (
        <>
            <SEO
                title={`${category} - Денас`}
                description={'Открийте изкуството на красотата в нашето козметично студио. Разгледайте нашите първокласни услуги, пригодени да подобрят вашата естествена привлекателност.'}
                canonicalUrl={`https://www.denasbg.com${pathName}`}
            />

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">Услуги</h6>
                        <h1 className="mb-5">{category}</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {currentProcedures.map(procedure => (
                            <ProcedureItem key={procedure.name} {...procedure} />
                        ))}
                    </div>

                    {/* Pagination controls */}
                    {totalPages > 1 && (
                        <div className="d-flex justify-content-center mt-4">
                            <button 
                                className="btn btn-primary mx-2" 
                                onClick={prevPage} 
                                disabled={currentPage === 1}
                            >
                                Предишна
                            </button>
                            <span className="mx-2">Страница {currentPage} от {totalPages}</span>
                            <button 
                                className="btn btn-primary mx-2" 
                                onClick={nextPage} 
                                disabled={currentPage === totalPages}
                            >
                                Следваща
                            </button>
                        </div>
                    )}
                </div>
                <BackToTopButton />
            </div>
        </>
    );
}

export default ProcedureList;